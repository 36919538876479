import * as Preact from "preact";
import { memo } from "preact/compat";
import { useCallback, useContext, useEffect } from "preact/hooks";
import { ZOOM_FACTOR } from "~/model";
import { LabelTooltip, MapItemDetails, Popover } from "~/view/components";
import {
  useSingleClickDetection,
  useStateIfMounted,
  useValueRef,
} from "~/view/utils";

export const TownMapItem: Preact.FunctionComponent<{
  data: AreaData;
  active: boolean;
  doTransition?: boolean;
  setCurrentArea: (area: string) => void;
}> = memo(({ data, active, doTransition, setCurrentArea }) => {
  const zoom = useContext(ZOOM_FACTOR);

  const on_click = useCallback(
    e => {
      if (e.cancelClick) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      setCurrentArea(data.id);
      window.dispatchEvent(new CustomEvent("cancel-grab"));
    },
    [setCurrentArea]
  );

  const [is_active, set_is_active] = useStateIfMounted(active);
  useEffect(() => {
    set_is_active(active);
  }, [active]);

  return (
    <div
      className="map-item"
      data-transition={`${doTransition}`}
      data-active={`${is_active}`}
      style={{
        left: `${data.layout.position[0]}%`,
        top: `${data.layout.position[1]}%`,
        transform: `scale(${zoom})`,
      }}
    >
      <TownMapItemContent data={data} onClick={on_click} />
    </div>
  );
});

export const TownMapItemContent: Preact.FunctionComponent<{
  data: AreaData;
  onClick: EventListener;
}> = memo(({ data, onClick }) => {
  const on_click = useValueRef(onClick);
  const listeners = useSingleClickDetection(on_click);

  return (
    <div className="map-item-inner">
      <div className="map-item-content" data-direction={data.layout.direction}>
        <Popover>
          <MapItemDetails data={data} />
        </Popover>
        <LabelTooltip
          id={data.id}
          direction={data.layout.direction}
          {...listeners}
        >
          {data.content.label}
        </LabelTooltip>
      </div>
    </div>
  );
});
