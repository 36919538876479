import * as Preact from "preact";
import { useStateIfMounted } from "~/view/utils";

export const ScreenWarning: Preact.FunctionComponent = () => {
  const [hide, set_hide] = useStateIfMounted(false);

  if (hide) {
    return null;
  }

  return (
    <div className="screen-warning">
      For the best experience, view this site on a desktop or laptop computer.
      <div className="screen-warning-footer">
        <button
          className="btn btn-primary"
          onClick={() => {
            set_hide(true);
          }}
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};
