import * as Preact from "preact";

const createNamedContext = <T extends any>(
  initialValue: T,
  name
): Preact.Context<T> => {
  const ctx = Preact.createContext(initialValue);
  ctx.displayName = name;
  return ctx;
};

export const MAP_AREAS = createNamedContext<ObjectOf<AreaData> | null>(
  null,
  "MapAreas"
);

export const AREA_ORDER = createNamedContext<string[] | null>(
  null,
  "AreaOrder"
);

export const VIEWED_AREAS = createNamedContext<string[]>([], "ViewedAreas");

export const SET_VIEWED_AREA = createNamedContext<(area: string) => void>(
  () => {},
  "SetViewedArea"
);

export const CURRENT_AREA = createNamedContext<{
  current_area: string | null;
  set_current_area: (area: string) => void;
}>(
  {
    current_area: null,
    set_current_area: () => {},
  },
  "CurrentArea"
);

export const ZOOM_FACTOR = createNamedContext<number>(1, "ZoomFactor");
export const CURRENT_URL = createNamedContext<string>(
  window.location.pathname,
  "CurrentUrl"
);
