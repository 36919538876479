import * as hooks from "preact/hooks";
import { set_title, useStateRef } from "~/view/utils";

export const useDebounce = <T extends any[]>(
  callback: (...args: T) => void,
  delay: number = 150
) => {
  const timer = hooks.useRef<any>(null);
  const cb = hooks.useCallback(
    (...args: T) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        callback(...args);
        clearTimeout(timer.current);
        timer.current = null;
      }, delay);
    },
    [callback]
  );

  const cancel = hooks.useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);

  hooks.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      timer.current = null;
    };
  }, [callback]);

  return [cb, cancel] as const;
};

export const MOVE_DIST_TO_CANCEL_CLICK = {
  touchmove: 6,
  mousemove: 2,
};

// detect a mouse down/up action on a single target element, cancel if the mouse is dragged
export const useSingleClickDetection = (
  callbackRef: preact.RefObject<EventListener>,
  onClickStart?: preact.RefObject<EventListener>,
  onClickCancel?: preact.RefObject<EventListener>
) => {
  const initial_pos = hooks.useRef<any>({});
  const [mouse_down, setMouseDown, mouse_down_ref] =
    useStateRef<boolean>(false);
  const onMouseMove = hooks.useCallback(e => {
    const distance = MOVE_DIST_TO_CANCEL_CLICK[e.type];
    const x = e.type === "touchmove" ? e.changedTouches[0].clientX : e.clientX;
    const y = e.type === "touchmove" ? e.changedTouches[0].clientY : e.clientY;
    if (
      Math.abs(x - initial_pos.current.x) > distance ||
      Math.abs(y - initial_pos.current.y) > distance
    ) {
      setMouseDown(false);
      onClickCancel?.current?.(e);
    }
  }, []);
  const onMouseUp = hooks.useCallback(e => {
    const { current } = mouse_down_ref;
    setMouseDown(false);
    if (current) {
      return callbackRef.current ? callbackRef.current(e) : undefined;
    }
  }, []);
  const onWindowFocus = hooks.useCallback(e => {
    if (e.target === window) {
      setMouseDown(false);
    }
  }, []);

  hooks.useEffect(() => {
    if (!mouse_down && initial_pos.current.target) {
      initial_pos.current.target.removeEventListener("mousemove", onMouseMove);
      initial_pos.current.target.removeEventListener("touchmove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("touchend", onMouseUp);
      window.removeEventListener("focus", onWindowFocus);
      initial_pos.current = {};
    }
  }, [mouse_down]);
  return hooks.useMemo(() => {
    const listener = e => {
      if (e.type === "mousedown") {
        if (e.button !== 0) {
          return;
        }
        initial_pos.current = { x: e.clientX, y: e.clientY, target: e.target };
      } else {
        initial_pos.current = {
          x: e.changedTouches[0].clientX,
          y: e.changedTouches[0].clientY,
          target: e.target,
        };
      }
      setMouseDown(true);
      onClickStart?.current?.(e);
      e.target.addEventListener("mousemove", onMouseMove);
      e.target.addEventListener("touchmove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp, { once: true });
      window.addEventListener("touchend", onMouseUp, { once: true });
      window.addEventListener("focus", onWindowFocus, { once: true });
    };
    return {
      onMouseDown: listener,
      onTouchStart: listener,
    };
  }, [mouse_down]);
};

// takes a callback to generate the title, and inputs to trigger the hooks,
// returns a callback to manually force a title update
export const useDocumentTitle = (
  callback: () => string | null,
  inputs: any[]
) => {
  hooks.useEffect(() => {
    const document_title = callback();
    if (document_title && document.title !== document_title) {
      set_title(document_title);
    }
  }, inputs);
  return hooks.useCallback(() => {
    const document_title = callback();
    if (document_title && document.title !== document_title) {
      set_title(document_title);
    }
  }, inputs);
};
