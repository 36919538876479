import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { HTML_PREFIX, IMAGES_PREFIX } from "~/common";
import { ButtonWithIcon, CloseIcon, useModal } from "~/view/components";
import { useVideoMeta } from "~/view/utils";

export const MediaModal: Preact.FunctionComponent<
  AreaMedia & {
    platform?: "vimeo" | "youtube";
    embedUrl?: string;
    dimensions?: Dimensions;
    dismiss: () => void;
  }
> = ({
  src,
  type,
  description,
  thumbnailUrl,
  size,
  platform,
  embedUrl,
  dimensions,
  dismiss,
}) => {
  const style = useMemo(() => {
    if (size) {
      return {
        width: `${size.width}px`,
        height: `${size.height}px`,
      };
    }
    if (dimensions) {
      const aspect = dimensions.height / dimensions.width;
      return {
        maxWidth: `min(${100 / aspect}vh - ${7.5 / aspect}rem, 100vw - 5rem)`,
        paddingTop: `${aspect * 100}%`,
      };
    }
  }, [size, dimensions]);
  return (
    <div className="media-modal-content">
      <div className="media-modal-header">
        <ButtonWithIcon
          side="left"
          icon={<CloseIcon />}
          className="btn btn-white"
          onClick={dismiss}
        >
          Close
        </ButtonWithIcon>
      </div>
      <div className="media-modal-body">
        {type === "image" ? (
          <img
            className="media-modal-image"
            style={style}
            src={src}
            alt={description}
          />
        ) : type === "audio" ? (
          <div className="media-modal-audio" style={style}>
            <img src={thumbnailUrl} alt={description} />
            <audio controls={true}>
              <source src={src} />
            </audio>
          </div>
        ) : type === "embed" ? (
          <iframe
            style={style}
            data-styled={!!style}
            src={`${window.location.origin}${HTML_PREFIX}/${src}`}
          />
        ) : platform === "youtube" ? (
          <MediaEmbedYoutube src={embedUrl} style={style} />
        ) : platform === "vimeo" ? (
          <MediaEmbedVimeo src={embedUrl} style={style} />
        ) : (
          <video style={style} className="media-modal-video" controls={true}>
            <source src={src} />
          </video>
        )}
      </div>
    </div>
  );
};

export const MediaItem: Preact.FunctionComponent<AreaMedia> = ({
  src,
  thumbnailUrl,
  description,
  size,
  ...props
}) => {
  const [Modal, set_open] = useModal();
  const type = props.type;
  const icon = props.type === "embed" ? props.icon : undefined;
  const [thumbnail, thumbnail_alt, platform, embed_url, dimensions] =
    useVideoMeta({
      type,
      src,
      thumbnailUrl,
      description,
      size,
      // @ts-expect-error:
      icon: props.icon,
    });

  const icon_type = icon ?? type;
  const icon_img =
    icon_type === "audio"
      ? "Headphones.svg"
      : icon_type === "video"
      ? "PlayCircle.svg"
      : undefined;

  return (
    <button
      className="area-modal-media-item"
      title={thumbnail_alt}
      onClick={() => set_open(true)}
    >
      <div className="area-modal-media-item-thumbnail">
        {thumbnail ? (
          <img src={thumbnail} alt={thumbnail_alt} />
        ) : (
          <div className="area-modal-media-item-placeholder" />
        )}
      </div>
      {icon_img && (
        <img
          className="area-modal-media-item-icon"
          src={`${IMAGES_PREFIX}/${icon_img}`}
          alt=""
        />
      )}
      <Modal className="media-modal">
        <MediaModal
          {...{ type, size, src, description, platform }}
          thumbnailUrl={thumbnail}
          embedUrl={embed_url}
          dimensions={dimensions}
          dismiss={() => set_open(false)}
        />
      </Modal>
    </button>
  );
};

export const MediaEmbedYoutube: Preact.FunctionComponent<
  { src: string } & HTMLDivProps
> = ({ src, style }) => (
  <div
    className="media-modal-video"
    style={
      typeof style === "object"
        ? {
            ...style,
            position: "relative",
          }
        : "padding:69.84% 0 0 0;position:relative;"
    }
  >
    <iframe
      id="ytplayer"
      type="text/html"
      src={src}
      style="position:absolute;top:0;left:0;width:100%;height:100%;"
      frameBorder="0"
    />
  </div>
);

export const MediaEmbedVimeo: Preact.FunctionComponent<
  { src: string } & HTMLDivProps
> = ({ src, style }) => (
  <Preact.Fragment>
    <div
      className="media-modal-video"
      style={
        typeof style === "object"
          ? {
              ...style,
              position: "relative",
            }
          : "padding:69.84% 0 0 0;position:relative;"
      }
    >
      <iframe
        src={src}
        style="position:absolute;top:0;left:0;width:100%;height:100%;"
        frameBorder="0"
        // @ts-expect-error:
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullscreen={true}
      />
    </div>
    <script src="https://player.vimeo.com/api/player.js"></script>
  </Preact.Fragment>
);
