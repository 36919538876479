export const maybeClassName = (className?: string) =>
  className ? ` ${className}` : "";

// potentially focusable = would be focusable if the element
// doesn't have [tabindex="-1"]
// https://stackoverflow.com/a/38865836
export const POTENTIAL_FOCUSABLE_SELECTORS = [
  "a[href]",
  "area[href]",
  'input:not([disabled]):not([type="hidden"])',
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  "iframe",
  "object",
  "embed",
  "*[tabindex]:not([tabindex='-1'])",
  "*[contenteditable]",
];
export const FOCUSABLE_SELECTORS = POTENTIAL_FOCUSABLE_SELECTORS.map(s => {
  const extra = ":not([tabindex='-1'])";
  return s.endsWith(extra) ? s : `${s}:not([tabindex='-1'])`;
});

// get first focusable element in a container
export const getFirstFocusable = (
  container: HTMLElement,
  prefix?: string
): HTMLElement => {
  if (!prefix) {
    return container.querySelector(FOCUSABLE_SELECTORS.join(", "));
  }
  return container.querySelector(
    FOCUSABLE_SELECTORS.map(s => `${prefix} ${s}`).join(", ")
  );
};

// https://stackoverflow.com/a/47804062
export const reset_page_zoom = () => {
  const viewport: HTMLMetaElement | null = document.querySelector(
    'meta[name="viewport"]'
  );
  const parent = viewport?.parentElement ?? document.head;
  const new_vp = document.createElement("META") as HTMLMetaElement;
  new_vp.setAttribute("name", "viewport");
  new_vp.content =
    "initial-scale=1,minimum-scale=1,maximum-scale=1,width=device-width";
  if (viewport) {
    parent.removeChild(viewport);
  }
  parent.appendChild(new_vp);
  setTimeout(() => {
    parent.removeChild(new_vp);
    if (viewport) {
      parent.appendChild(viewport);
    }
  }, 100);
};

export const make_title = (parts: string[]) =>
  parts.concat(["Explore United Way's Work in the Community"]).join(" | ");

export const set_title = (title: string) => {
  document.title = title;
};
