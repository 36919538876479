import * as Preact from "preact";
import { useEffect, useRef } from "preact/hooks";
import { maybeClassName, useStateIfMounted } from "~/view/utils";

const NUB_W = 40;
const NUB_H = 53;

export const Label: Preact.FunctionComponent<HTMLDivProps> = ({
  className,
  ...props
}) => <div {...props} className={`label${maybeClassName(className)}`} />;

export const LabelTooltip: Preact.FunctionComponent<
  HTMLDivProps & { direction?: "left" | "right" }
> = ({ direction = "right", className, children, ...props }) => {
  const label_ref = useRef<HTMLDivElement>();
  const [dimensions, set_dimensions] = useStateIfMounted<Dimensions | null>(
    null
  );
  useEffect(() => {
    if (
      label_ref.current?.scrollWidth !== dimensions?.width &&
      label_ref.current?.scrollHeight !== dimensions?.height
    ) {
      // wait for DOM to settle
      setTimeout(() => {
        set_dimensions({
          width: label_ref.current.scrollWidth + 2,
          height: label_ref.current.scrollHeight + 1,
        });
      }, 100);
    }
  }, [label_ref.current?.scrollWidth, label_ref.current?.scrollWidth]);

  const { width: x, height: y } = dimensions ?? { width: 192, height: 1 };
  const full_w = x + 6;
  const full_h = NUB_H + y + 6;
  const s = direction === "left" ? -1 : 1;
  return (
    <div
      {...props}
      className={`label-tooltip${maybeClassName(className)}`}
      data-dir={direction}
      data-ready={`${!!dimensions}`}
    >
      <div
        ref={label_ref}
        className="label"
        style={dimensions ? { width: `${x}px` } : undefined}
      >
        {children}
      </div>
      {!!dimensions && (
        <svg
          class="label-tooltip-shape"
          width={full_w}
          height={full_h + 8}
          viewBox={`${direction === "left" ? -full_w : 0} -${y + 8} ${full_w} ${
            full_h + 8
          }`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="label-tooltip-shadow"
            d={`M${s * 2} 0V${NUB_H + 3}L${s * (NUB_W * 1.2)} ${0.2 * NUB_H}H${
              s * x * 0.9
            }L${s * x * 1.05}${-y + 1}H${s}V0Z`}
            fill={`url(#svg_shadow_${props.id})`}
            transform="translate(0, 2)"
          />
          <path
            className="label-tooltip-background"
            d={`M0 0V${NUB_H}L${s * NUB_W} 0H${s * x}V${-y}H0V0Z`}
          />
          <defs>
            <linearGradient
              id={`svg_shadow_${props.id}`}
              x1={Math.round(s > 0 ? 0 : -full_w)}
              y1={Math.round(s > 0 ? NUB_H : 0.2 * NUB_H)}
              x2={Math.round(s > 0 ? full_w : 0)}
              y2={Math.round(s > 0 ? 0.2 * NUB_H : NUB_H)}
              gradientUnits="userSpaceOnUse"
            >
              <stop
                offset="0"
                stop-color="#010101"
                stop-opacity={s > 0 ? "0.5" : "0"}
              />
              <stop
                offset={s > 0 ? 0.9 : 0.1}
                stop-color="#010101"
                stop-opacity="0"
              />
              <stop
                offset="1"
                stop-color="#010101"
                stop-opacity={s > 0 ? "0" : "0.5"}
              />
            </linearGradient>
          </defs>
        </svg>
      )}
    </div>
  );
};
