import * as Preact from "preact";
import { IMAGES_PREFIX } from "~/common";
import { Label } from "~/view/components";
import { useStateIfMounted } from "~/view/utils";

export const Illustration: Preact.FunctionComponent<{
  data: AreaDetailsIllustration;
}> = ({ data }) => {
  const [ready, set_ready] = useStateIfMounted(false);

  return (
    <div className="area-modal-image-container">
      <div
        className="area-modal-image"
        style={{
          shapeOutside: `url(${IMAGES_PREFIX}/${data.image})`,
        }}
      >
        {ready &&
          data.points.map(({ position, text }, i) => (
            <Label
              key={i}
              style={{
                left: `${position[0]}%`,
                top: `${position[1]}%`,
              }}
            >
              {text}
            </Label>
          ))}
        <img
          src={`${IMAGES_PREFIX}/${data.image}`}
          alt={data.description}
          onLoad={() => set_ready(true)}
        />
      </div>
    </div>
  );
};
