import { stringify } from "query-string";

export const YOUTUBE_API_URL = "https://www.googleapis.com/youtube/v3/videos";
export const VIMEO_API_URL = "https://vimeo.com/api/oembed.json";

export const YOUTUBE_URL_REGEX =
  /\b(?:https?:\/\/)?(?:(?:(?:(?:www|m)\.)?youtube\.com(?:\/watch\?v=|(?:\/(?:embed|v)\/)))|youtu\.be\/)((?:\w|-)+)/i;
export const VIMEO_URL_REGEX =
  /\b(?:https?:\/\/)?(?:www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/i;
// from https://stackoverflow.com/a/50777192

export const is_youtube_video_link = (link: string) =>
  YOUTUBE_URL_REGEX.test(link);

export const is_vimeo_video_link = (link: string) => VIMEO_URL_REGEX.test(link);

export const get_youtube_embed_url = (url: string) => {
  if (/^(https?:\/\/)?(www\.)?youtube.com\/embed\//i.test(url)) {
    return url;
  }
  const [, video_id] = url.match(YOUTUBE_URL_REGEX) || [];
  return video_id ? `https://www.youtube.com/embed/${video_id}` : "";
};

export const get_vimeo_embed_url = (url: string) =>
  url.replace(
    /^(https?:\/\/)?(www\.|player\.)?(vimeo\.com)/i,
    "https://player.vimeo.com"
  );

/** copied from mindgrub library */
const path_or = (def, path: Array<string | number>, obj: object) => {
  let ret = obj;
  for (let key of path) {
    if (ret == null) return def;
    ret = ret[key];
  }
  return ret == null ? def : ret;
};

interface VideoLinkMeta {
  url: string;
  title: string;
  thumbnail_url: string;
  description: string;
  dimensions?: Dimensions;
}

export const scrape_video_link = (
  link: string,
  api_key: string
): Promise<VideoLinkMeta | null> => {
  // get the metadata api url for this particular video link
  const url = get_scrape_url(link, api_key);
  if (!url) {
    return Promise.resolve(null);
  }

  return new Promise<VideoLinkMeta | null>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.onload = e => {
      console.debug(`scraper response: `, e);
      if (xhr.status >= 400) {
        let response = xhr.response;
        try {
          response = JSON.parse(xhr.response);
        } catch (e) {}
        reject({ status: xhr.status, response });
      } else {
        const response = xhr.response ? JSON.parse(xhr.response) : null;
        // extract the metadata from the response
        resolve(get_meta_from_scrape(response, link));
      }
    };
    xhr.onerror = () => {
      console.error(`scrape_video_link error: `, xhr);
      reject({ status: xhr.status, response: JSON.parse(xhr.response) });
    };
    xhr.send();
  });
};

export const get_scrape_url = (
  link: string,
  api_key: string
): string | null => {
  if (!link) {
    return null;
  }
  return (
    get_youtube_scrape_url(link, api_key) || get_vimeo_scrape_url(link) || null
  );
};

export const get_youtube_scrape_url = (
  link: string,
  api_key: string
): string | null => {
  if (!api_key || !link) {
    return null;
  }
  let [, id] = link.match(YOUTUBE_URL_REGEX) || [];
  if (id) {
    return `${YOUTUBE_API_URL}?${stringify(
      {
        id,
        part: ["id", "snippet", "player"],
        maxWidth: window.innerWidth,
        maxHeight: window.innerHeight,
        key: api_key,
      },
      {
        arrayFormat: "comma",
      }
    )}`;
  }

  return null;
};

export const get_vimeo_scrape_url = (link: string): string | null => {
  if (is_vimeo_video_link(link)) {
    return `${VIMEO_API_URL}?url=${encodeURIComponent(link)}`;
  }
  return null;
};

export const get_meta_from_scrape = (
  response: any,
  link: string
): VideoLinkMeta | null => {
  if (!response || !link) {
    return null;
  }
  if (is_youtube_video_link(link)) {
    return get_meta_from_youtube_scrape(response, link);
  }
  if (is_vimeo_video_link(link)) {
    return get_meta_from_vimeo_scrape(response, link);
  }
  return null;
};

export const get_meta_from_youtube_scrape = (
  response: any,
  link: string
): VideoLinkMeta | null => {
  if (!response || !response.items?.length || !link) {
    return null;
  }
  const output: VideoLinkMeta = {
    url: link,
    title: path_or("", ["items", 0, "snippet", "localized", "title"], response),
    thumbnail_url: path_or(
      "",
      ["items", 0, "snippet", "thumbnails", "medium", "url"],
      response
    ),
    description: path_or(
      "",
      ["items", 0, "snippet", "localized", "description"],
      response
    ),
  };
  const { embedWidth, embedHeight } = path_or(
    {},
    ["items", 0, "player"],
    response
  );
  const width = parseInt(embedWidth);
  const height = parseInt(embedHeight);
  if (!isNaN(width) && !isNaN(height)) {
    output.dimensions = { width, height };
  }

  return output;
};

export const get_meta_from_vimeo_scrape = (
  response: any,
  link: string
): VideoLinkMeta | null =>
  response && link
    ? {
        url: link,
        title: response.title,
        thumbnail_url: response.thumbnail_url,
        description: response.description,
      }
    : null;
