import * as Preact from "preact";
import { useCallback, useEffect } from "preact/hooks";
import {
  ButtonWithIcon,
  InfoIcon,
  ModalContent,
  useModal,
} from "~/view/components";
import { make_title, set_title } from "~/view/utils";

export const Outro: Preact.FunctionComponent<{
  data: AreaData;
  active: boolean;
  setCurrentArea: (area: string) => void;
}> = ({ data, active, setCurrentArea }) => {
  const [Modal, set_open] = useModal();

  const dismiss = useCallback(() => {
    if (active) {
      setCurrentArea(null);
    }
    set_open(false);
    set_title(make_title([]));
  }, [active]);

  useEffect(() => {
    if (active) {
      set_open(true);
    }
  }, [active]);

  return (
    <div className="map-about">
      <ButtonWithIcon
        className="btn-primary"
        icon={<InfoIcon />}
        side="left"
        onClick={() => set_open(true)}
      >
        About
      </ButtonWithIcon>
      <Modal className="area-modal area-modal-outro">
        <ModalContent content={data.content} dismiss={dismiss} />
      </Modal>
    </div>
  );
};
