import * as hooks from "preact/hooks";
import {
  get_vimeo_embed_url,
  get_youtube_embed_url,
  is_vimeo_video_link,
  is_youtube_video_link,
  scrape_video_link,
  useStateIfMounted,
} from "~/view/utils";

export const useVideoMeta = (media: AreaMedia) => {
  const [thumb_url, set_thumb_url] = useStateIfMounted(
    media.type === "image" ? media.src : media.thumbnailUrl
  );
  const [dimensions, set_dimensions] = useStateIfMounted<
    Dimensions | undefined
  >(undefined);
  const [fetched, set_fetched] = useStateIfMounted(
    thumb_url ? media.src : undefined
  );

  const platform = hooks.useMemo(
    () =>
      media.type === "video"
        ? is_vimeo_video_link(media.src)
          ? "vimeo"
          : is_youtube_video_link(media.src)
          ? "youtube"
          : undefined
        : undefined,
    [media.src]
  );

  const embed_url = hooks.useMemo(() => {
    if (platform === "youtube") {
      return get_youtube_embed_url(media.src);
    }
    if (platform === "vimeo") {
      return get_vimeo_embed_url(media.src);
    }
  }, [media.src, media.type]);

  const [thumb_alt, set_thumb_alt] = useStateIfMounted(media.description);
  hooks.useEffect(() => {
    if (media.type === "video" && (!thumb_url || media.src !== fetched)) {
      // @ts-expect-error:
      scrape_video_link(media.src, process.env.UW_GAPI_KEY)
        .then(meta => {
          if (!meta) {
            return;
          }
          const { title, url, thumbnail_url, dimensions } = meta;
          set_thumb_url(thumbnail_url);
          set_thumb_alt(title);
          set_dimensions(dimensions);
          set_fetched(url);
        })
        .catch(err => {
          console.error(`Failed to fetch video thumbnail:`, err);
        });
    }
  }, [media.src]);

  return [thumb_url, thumb_alt, platform, embed_url, dimensions] as const;
};
