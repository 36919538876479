import * as Preact from "preact";
import { forwardRef } from "preact/compat";
import { IMAGES_PREFIX } from "~/common";
import { maybeClassName } from "~/view/utils";

type ButtonWithIconProps = {
  icon: string | Preact.VNode;
  side?: "left" | "right";
} & Omit<HTMLButtonProps, "icon">;

export const ButtonWithIcon: Preact.FunctionComponent<ButtonWithIconProps> =
  forwardRef<HTMLButtonElement, ButtonWithIconProps>(
    ({ icon, side, className, children, ...props }, ref) => {
      const icon_only =
        !children || (Array.isArray(children) && children.length === 0);
      const icon_ = (
        <div className="btn-with-icon__icon">
          {typeof icon !== "string" ? (
            icon
          ) : (
            <img alt="" src={`${IMAGES_PREFIX}/${icon}`} />
          )}
        </div>
      );
      return (
        <button
          {...props}
          className={`btn btn-with-icon${
            icon_only ? " icon-only" : ""
          }${maybeClassName(className)}`}
          data-side={side}
          ref={ref}
        >
          {icon_only || side === "left" ? icon_ : null}
          {icon_only ? null : (
            <div className="btn-with-icon__content">{children}</div>
          )}
          {!icon_only && side === "right" ? icon_ : null}
        </button>
      );
    }
  );

export const LinkWithIcon: Preact.FunctionComponent<
  { icon: string | Preact.VNode; side?: "left" | "right" } & Omit<
    HTMLAnchorProps,
    "icon"
  >
> = ({ icon, side, className, children, ...props }) => {
  const icon_only =
    !children || (Array.isArray(children) && children.length === 0);
  const icon_ = (
    <div className="btn-with-icon__icon">
      {typeof icon !== "string" ? (
        icon
      ) : (
        <img alt="" src={`${IMAGES_PREFIX}/${icon}`} />
      )}
    </div>
  );
  return (
    <a
      {...props}
      className={`btn btn-with-icon${
        icon_only ? " icon-only" : ""
      }${maybeClassName(className)}`}
      data-side={side}
    >
      {icon_only || side === "left" ? icon_ : null}
      {icon_only ? null : (
        <div className="btn-with-icon__content">{children}</div>
      )}
      {!icon_only && side === "right" ? icon_ : null}
    </a>
  );
};
