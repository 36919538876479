import * as Preact from "preact";
import { createPortal } from "preact/compat";
import { useCallback, useEffect, useLayoutEffect, useRef } from "preact/hooks";
import {
  getFirstFocusable,
  maybeClassName,
  useStateRef,
  useValueRef,
} from "~/view/utils";

export const Modal: Preact.FunctionComponent<ModalProps> = ({
  className,
  id,
  dismiss,
  closeOnClickBackdrop = true,
  children,
  backdropData,
  ...props
}) => {
  const modal_ref = useRef<HTMLDivElement>();
  const mounted = useRef(false);
  const silent = useRef(false);
  const on_focus = useCallback(e => {
    if (
      mounted.current &&
      (!(e.target instanceof Node) || !modal_ref.current?.contains(e.target))
    ) {
      e.stopPropagation();
      if (!silent.current) {
        silent.current = true;
        getFirstFocusable(modal_ref.current)?.focus();
        silent.current = false;
      }
    }
  }, []);

  useLayoutEffect(() => {
    mounted.current = true;
    getFirstFocusable(modal_ref.current)?.focus();
    window.addEventListener("focus", on_focus, { capture: true });
    return () => {
      window.removeEventListener("focus", on_focus, { capture: true });
      mounted.current = false;
    };
  }, []);

  const listener = useCallback(e => {
    if (e.key === "Escape") {
      e.stopPropagation();
      e.preventDefault();
      dismiss();
    }
  }, []);
  useEffect(() => {
    const root = document.getElementById("app");
    root?.setAttribute("data-modal-open", "true");
    const capture = document.querySelectorAll(".modal-backdrop").length > 1;
    if (closeOnClickBackdrop) {
      window.addEventListener("keydown", listener, { capture });
    }
    return () => {
      window.removeEventListener("keydown", listener, { capture });
      if (document.querySelectorAll(".modal-backdrop").length === 1) {
        root?.removeAttribute("data-modal-open");
      }
    };
  }, [listener]);

  return (
    <div
      className="modal-backdrop"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        if (closeOnClickBackdrop) {
          dismiss();
        }
      }}
      {...backdropData}
    >
      <div
        id={id}
        ref={modal_ref}
        className={`modal${maybeClassName(className)}`}
        onClick={e => {
          e.stopPropagation();
          e.stopImmediatePropagation();
        }}
        {...props}
      >
        {children}
      </div>
    </div>
  );
};

const MODAL_TRANSITION_TIME = 400; // ms

export const useModal = (
  container_selector: string = "#modal-container",
  on_close?: () => void
) => {
  const container = useValueRef<HTMLDivElement>(
    document.querySelector(container_selector)
  );
  const [open, set_open, open_ref] = useStateRef<boolean>(false);
  const [, set_visible, visible_ref] = useStateRef<boolean>(false);

  const toggle_modal = useCallback((should_open: boolean) => {
    if (visible_ref.current === should_open) {
      return;
    }
    if (should_open) {
      set_open(true);
    } else {
      setTimeout(() => {
        set_open(should_open);
      }, MODAL_TRANSITION_TIME);
    }
    set_visible(should_open);
  }, []);

  const dismiss = useCallback(() => {
    toggle_modal(false);
    on_close?.();
  }, [toggle_modal, on_close]);

  const content = useCallback(
    (props: Preact.RenderableProps<Omit<ModalProps, "dismiss">>) =>
      open_ref.current && container.current
        ? createPortal(
            <Modal
              {...props}
              backdropData={{
                "data-visible": `${visible_ref.current}`,
              }}
              dismiss={dismiss}
            />,
            container.current
          )
        : null,
    []
  );

  return [content, toggle_modal, open] as const;
};
