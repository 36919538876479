import * as Preact from "preact";
import { getCurrentUrl, route } from "preact-router";
import { useContext, useEffect, useMemo } from "preact/hooks";
import { CURRENT_URL } from "~/model";
import { Illustration, LinkWithIcon, MediaItem } from "~/view/components";
import { make_title, set_title } from "~/view/utils";
import { ButtonWithIcon } from "../common";
import { ArrowIcon } from "../icons";

export const ModalContent: Preact.FunctionComponent<{
  content: AreaContent;
  dismiss: () => void;
}> = ({ content, dismiss }) => {
  const url = useContext(CURRENT_URL);
  useEffect(() => {
    if (content.key !== "about") {
      set_title(make_title([content.label, "Details"]));
      route(`/${content.key}/details`);
      return () => {
        set_title(make_title([content.label]));
        route(`/${content.key}`);
      };
    } else {
      set_title(make_title([content.label]));
      route("/about");
    }
  }, []);

  useEffect(() => {
    if (
      url === `/${content.key}/details` ||
      (content.key === "about" && url === "/about")
    ) {
      return () => {
        if (content.key === "about") {
          set_title(make_title([]));
          route("/", getCurrentUrl() !== url);
        } else {
          set_title(make_title([content.label]));
          route(`/${content.key}`, getCurrentUrl() !== url);
        }
        dismiss();
      };
    }
  }, [url]);

  return (
    <div className="area-modal-content">
      <div className="area-modal-back-button">
        <ButtonWithIcon
          side="left"
          icon={<ArrowIcon direction="left" />}
          className="btn btn-white"
          onClick={dismiss}
        >
          BACK
        </ButtonWithIcon>
      </div>
      <div className="area-modal-info">
        <h1 className="area-modal-headline">{content.headline}</h1>
        {content.media?.length > 0 && (
          <div className="area-modal-media">
            {content.media.map(m => (
              <MediaItem key={m.src} {...m} />
            ))}
          </div>
        )}
        <h2 className="area-modal-subhead">{content.subhead}</h2>
        <Illustration data={content.illustration} />
        <div className="area-modal-body">
          <BodyText>{content.body}</BodyText>
        </div>
        <div className="area-modal-footer">
          <LinkWithIcon
            side="right"
            icon={<ArrowIcon direction="right" />}
            className="btn btn-secondary"
            href={content.cta.url}
            target="_blank"
          >
            {content.cta.text}
          </LinkWithIcon>
          {content.morelink && (
            <LinkWithIcon
              side="right"
              icon={<ArrowIcon direction="right" />}
              className="btn btn-primary"
              href={content.morelink.url}
              target="_blank"
            >
              {content.morelink.text}
            </LinkWithIcon>
          )}
        </div>
      </div>
      <Illustration data={content.illustration} />
    </div>
  );
};

const UL_REGEX = /^\s*?[•-]\s*/;
export const BodyText = ({ children }: { children: string }) => {
  const content = useMemo(() => {
    const paragraphs = children.split(/\s*?\n\s*?\n/);
    return paragraphs.map(p => {
      const lines = p.split("\n");
      const output: Preact.ComponentChildren[] = [];
      let list = [];
      while (lines.length > 0) {
        const line = lines.shift();
        if (UL_REGEX.test(line)) {
          list.push(line.replace(UL_REGEX, ""));
        } else {
          if (list.length > 0) {
            output.push(
              <ul key={lines.length}>
                {list.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            );
            list = [];
          }
          output.push(line);
        }
      }
      if (list.length > 0) {
        output.push(
          <ul key={lines.length}>
            {list.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        );
      }
      return output;
    });
  }, [children]);

  return (
    <Preact.Fragment>
      {content.map((par, i) => (
        <p key={i}>{par}</p>
      ))}
    </Preact.Fragment>
  );
};
