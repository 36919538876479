import * as Preact from "preact";
import { maybeClassName } from "~/view/utils";

export const ArrowIcon: Preact.FunctionComponent<
  MaybeClass & { direction?: "left" | "right" }
> = ({ className, direction }) => (
  <svg
    data-icon="arrow"
    data-dir={direction}
    className={`icon${maybeClassName(className)}`}
    width="18"
    height="18"
    viewBox="-1 -2 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 0L18 8L10 16V9H0V7H10V0Z" />
  </svg>
);
