import * as Preact from "preact";
import { maybeClassName } from "~/view/utils";

export const CloseIcon: Preact.FunctionComponent<MaybeClass> = ({
  className,
}) => (
  <svg
    data-icon="close"
    className={`icon${maybeClassName(className)}`}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2.00854L2.01709 15.9915"
      stroke-width="2"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M16 15.9915L2.01709 2.00854"
      stroke-width="2"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </svg>
);
