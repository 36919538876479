import * as Preact from "preact";
import { route } from "preact-router";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
} from "preact/hooks";
import { IMAGES_PREFIX } from "~/common";
import { CURRENT_AREA, CURRENT_URL, MAP_AREAS } from "~/model";
import {
  Intro,
  Outro,
  ScreenWarning,
  TownMap,
  TownMapItem,
} from "~/view/components";
import { useStateIfMounted } from "~/view/utils";

const LOGO_URL =
  "https://uwcm.org?utm_source=Explore_Our_Work&utm_medium=website&utm_campaign=united_way_of_central_maryland";

export const View: Preact.FunctionComponent<{ url?: string }> = ({ url }) => {
  const areas = useContext(MAP_AREAS);
  const { current_area, set_current_area } = useContext(CURRENT_AREA);
  const [prev_area, set_prev_area] = useStateIfMounted(current_area);
  useLayoutEffect(() => {
    if (current_area !== prev_area) {
      setTimeout(() => {
        set_prev_area(current_area);
      }, 400);
    }
  }, [current_area]);

  useEffect(() => {
    const match = url.match(/^\/([a-z-]+)$/i);
    if (!match || !areas || !(match[1] in areas)) {
      return;
    }
    if (match[1] !== current_area && match[1] !== "about") {
      route("/");
    }
  }, [url]);

  const [map_ready, set_map_ready] = useStateIfMounted(false);
  const on_load = useCallback(() => set_map_ready(true), []);

  return areas ? (
    <CURRENT_URL.Provider value={url}>
      <div
        className="page-content"
        data-current-area={current_area}
        data-ready={`${!!map_ready && !!areas}`}
      >
        {areas?.["intro"] && (
          <Intro
            data={areas?.["intro"]}
            active={current_area === "intro"}
            doTransition={
              (current_area === "intro" || prev_area === "intro") &&
              current_area !== prev_area
            }
            setCurrentArea={set_current_area}
          />
        )}
        <TownMap
          currentAreaPosition={areas?.[current_area]?.layout ?? null}
          onLoad={on_load}
        >
          {areas &&
            Object.entries(areas).map(([key, data]) =>
              key === "intro" || key === "about" ? null : (
                <TownMapItem
                  key={key}
                  data={data}
                  active={key === current_area}
                  doTransition={
                    (current_area === key || prev_area === key) &&
                    current_area !== prev_area
                  }
                  setCurrentArea={set_current_area}
                />
              )
            )}
        </TownMap>
        {areas?.["about"] && (
          <Outro
            data={areas["about"]}
            active={current_area === "about"}
            setCurrentArea={set_current_area}
          />
        )}
      </div>
      <div id="modal-container" className="modal-container">
        <div className="logo">
          <a href={LOGO_URL} target="_blank">
            <img
              src={`${IMAGES_PREFIX}/uwcm-logo.png`}
              alt=""
              draggable={false}
            />
          </a>
        </div>
      </div>
      <ScreenWarning />
    </CURRENT_URL.Provider>
  ) : null;
};
