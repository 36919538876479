import * as hooks from "preact/hooks";

const useMounted = () => {
  const mounted = hooks.useRef<boolean>(true);
  hooks.useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  return mounted;
};

// useState, but prevents setState from being called after the component unmounts
export const useStateIfMounted = <T>(initial_value: T) => {
  const mounted = useMounted();
  const [value, setValue] = hooks.useState<T>(initial_value);
  const setter: hooks.StateUpdater<T> = hooks.useCallback(
    new_value => {
      if (mounted.current) {
        setValue(new_value);
      }
    },
    [setValue, mounted]
  );
  return [value, setter] as const;
};

// useState, but emulates the functionality of state/setState in a class component
export const useStateObject = <T extends object>(initial_value: T) => {
  const mounted = useMounted();
  const [value, setValue] = hooks.useState<T>(initial_value);
  const setter = hooks.useCallback(
    (new_state: Partial<T> | ((prev_state: T) => T)) => {
      if (!mounted.current) {
        return;
      }
      if (typeof new_state === "function") {
        setValue(new_state);
      } else {
        setValue(prev_state => ({
          ...prev_state,
          ...new_state,
        }));
      }
    },
    [setValue, mounted]
  );
  return [value, setter] as const;
};

export const useStateRef = <
  T extends string | number | boolean | object | null
>(
  initial_value: T
) => {
  const mounted = useMounted();
  const value_ref = hooks.useRef<T>(initial_value);
  const [value, setValue] = hooks.useState<T>(initial_value);
  const setter: hooks.StateUpdater<T> = hooks.useCallback(
    new_value => {
      if (mounted.current) {
        value_ref.current =
          typeof new_value === "function"
            ? new_value(value_ref.current)
            : new_value;
        setValue(new_value);
      }
    },
    [setValue, mounted]
  );
  return [value, setter, value_ref] as const;
};

export const useStateWithRef = <
  T extends string | number | boolean | object | null
>(
  initial_value: T,
  value_ref: preact.RefObject<T>
) => {
  const mounted = useMounted();
  const [value, setValue] = hooks.useState<T>(initial_value);
  hooks.useEffect(() => {
    value_ref.current = initial_value;
  }, []);
  const setter: hooks.StateUpdater<T> = hooks.useCallback(
    new_value => {
      if (mounted.current) {
        value_ref.current =
          typeof new_value === "function"
            ? new_value(value_ref.current)
            : new_value;
        setValue(new_value);
      }
    },
    [setValue, mounted]
  );
  return [value, setter] as const;
};

export const useValueRef = <T>(value: T) => {
  const ref = hooks.useRef<T>(value);
  ref.current = value;
  return ref;
};

export const useMemoRef = <T>(factory: () => T, inputs: any[]) => {
  const value = hooks.useMemo(factory, inputs);
  return useValueRef(value);
};
