import * as Preact from "preact";
import { maybeClassName } from "~/view/utils";

export const Popover: Preact.FunctionComponent<HTMLDivProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={`popover${maybeClassName(className)}`} {...props}>
      <div className="popover-content">{children}</div>
    </div>
  );
};
