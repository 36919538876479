import * as Preact from "preact";
import { memo } from "preact/compat";
import { useCallback, useEffect, useMemo, useRef } from "preact/hooks";
import { spring, Motion } from "preact-motion";
import { IMAGES_PREFIX } from "~/common";
import { ZOOM_FACTOR } from "~/model";
import { ButtonWithIcon } from "~/view/components";
import {
  MOVE_DIST_TO_CANCEL_CLICK,
  useDebounce,
  useStateIfMounted,
  useStateRef,
  useValueRef,
} from "~/view/utils";

const SPRING_PAN_CONFIG = {
  stiffness: 400,
  damping: 56,
};

const SPRING_ZOOM_CONFIG = {
  stiffness: 320,
  damping: 48,
};

const MIN_ZOOM = 0.5;
const MAX_ZOOM = 1.6;
const ZOOM_STEP = 0.1;

interface Coords {
  x: number;
  y: number;
}

const calc_min_zoom = (window_size: Dimensions, dimensions: Dimensions) =>
  Math.max(
    window_size.width / dimensions.width,
    window_size.height / dimensions.height,
    MIN_ZOOM
  );

// enforce min/max position so that the panning stops when the edge of the
// image is at the edge of the window
const clamp_position = (
  new_position: Coords,
  dimensions: Dimensions,
  win_size: Dimensions,
  zoom: number
): Coords => {
  const max = {
    x: dimensions.width * zoom - win_size.width,
    y: dimensions.height * zoom - win_size.height,
  };
  return {
    x: Math.min(Math.max(new_position.x, 0), max.x),
    y: Math.min(Math.max(new_position.y, 0), max.y),
  };
};

const wrap_touch_listener =
  (listener: EventListener): Preact.JSX.TouchEventHandler<any> =>
  (e: TouchEvent) => {
    if (!e.type.startsWith("touch")) {
      return listener(e);
    }
    const touch = e.changedTouches[0];
    if (!touch) {
      return listener(e);
    }
    // @ts-expect-error:
    e.x = touch.clientX;
    // @ts-expect-error:
    e.y = touch.clientY;
    return listener(e);
  };

const opts = { capture: true };
const silence_event = e => {
  e.stopPropagation();
};

export const TownMap: Preact.FunctionComponent<{
  currentAreaPosition: AreaLayout | null;
  onLoad: () => void;
}> = memo(({ currentAreaPosition, onLoad, children }) => {
  const [panning, set_panning, pan_ref] = useStateRef<boolean>(false);
  const [mouse_down, set_mouse_down] = useStateIfMounted(false);
  const img_ref = useRef<HTMLImageElement>();

  const [window_size, set_window_size, win_ref] =
    useStateRef<Dimensions | null>(null);
  const [zoom_factor, set_zoom_factor_, zoom_ref] = useStateRef<number>(null);
  const [position, set_position_, pos_ref] = useStateRef<Coords | null>(null);
  const [dimensions, set_dimensions, dim_ref] = useStateRef<Dimensions | null>(
    null
  );

  const set_zoom_factor = useCallback((zoom: number) => {
    if (!isNaN(zoom)) {
      set_zoom_factor_(zoom);
    }
  }, []);
  const set_position = useCallback((pos: Coords) => {
    if (!isNaN(pos.x) && !isNaN(pos.y)) {
      set_position_(pos);
    }
  }, []);

  const cur_area_ref = useValueRef(currentAreaPosition);
  const [init, set_init] = useStateIfMounted(false);
  const do_init = useCallback(() => {
    if (init) {
      return;
    }
    const init_dimensions = {
      width: img_ref.current?.naturalWidth,
      height: img_ref.current?.naturalHeight,
    };
    const init_win_size = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    const init_zoom = cur_area_ref.current?.zoom ?? 1;
    const init_position = cur_area_ref.current
      ? clamp_position(
          {
            x:
              (cur_area_ref.current.position[0] *
                init_dimensions.width *
                init_zoom) /
                100 -
              init_win_size.width / 2,
            y:
              ((cur_area_ref.current.position[1] - 4) *
                init_dimensions.height *
                init_zoom) /
                100 -
              init_win_size.height / 2,
          },
          init_dimensions,
          init_win_size,
          init_zoom
        )
      : {
          x: (init_dimensions.width - init_win_size.width) / 2,
          y: (init_dimensions.height - init_win_size.height) / 2,
        };

    set_window_size(init_win_size);
    set_dimensions(init_dimensions);
    set_zoom_factor(init_zoom);
    set_position(init_position);
    set_init(true);
  }, [init]);
  useEffect(() => {
    if (init) {
      onLoad();
    }
  }, [init]);

  // enforce min zoom level so the image will always fill the window
  const min_zoom = useMemo(
    () =>
      window_size && dimensions
        ? calc_min_zoom(window_size, dimensions)
        : MIN_ZOOM,
    [window_size, dimensions]
  );

  const [, set_mouse_start, mouse_start_ref] = useStateRef<Coords | null>(null);
  const [pan_pos, set_pan_pos, pan_pos_ref] = useStateRef<Coords | null>(null);

  const on_mouse_down = useCallback(e => {
    e.preventDefault();
    if (!!e.button) {
      return;
    }
    set_mouse_down(true);
    set_mouse_start({ x: e.x, y: e.y });
    set_pan_pos({ ...pos_ref.current });
  }, []);

  const on_mouse_up_ = useCallback(e => {
    e.preventDefault();
    set_mouse_down(false);
    if (pan_ref.current) {
      e.cancelClick = true;
      set_panning(false);
      set_position(
        clamp_position(
          e.type === "blur"
            ? pan_pos_ref.current
            : {
                x: pos_ref.current.x + (mouse_start_ref.current.x - e.x),
                y: pos_ref.current.y + (mouse_start_ref.current.y - e.y),
              },
          dim_ref.current,
          win_ref.current,
          zoom_ref.current
        )
      );
    }
    set_mouse_start(null);
    set_pan_pos(null);
  }, []);
  const on_mouse_up = useMemo(
    () => wrap_touch_listener(on_mouse_up_),
    [on_mouse_up_]
  );

  const on_mouse_move = useCallback(e => {
    const distance = MOVE_DIST_TO_CANCEL_CLICK[e.type];
    if (
      !pan_ref.current &&
      Math.abs(mouse_start_ref.current.x - e.x) <= distance &&
      Math.abs(mouse_start_ref.current.y - e.y) <= distance
    ) {
      return;
    }
    if (!pan_ref.current) {
      set_panning(true);
    }
    e.preventDefault();
    const new_pos = clamp_position(
      {
        x: pos_ref.current.x + (mouse_start_ref.current.x - e.x),
        y: pos_ref.current.y + (mouse_start_ref.current.y - e.y),
      },
      dim_ref.current,
      win_ref.current,
      zoom_ref.current
    );
    set_pan_pos(new_pos);
  }, []);

  const apply_zoom = useCallback((e, new_zoom: number) => {
    if (new_zoom === zoom_ref.current) {
      return;
    }
    let pos = panning ? pan_pos_ref.current : pos_ref.current;
    if (!pos) {
      return;
    }

    let { x, y } = e;
    if (e.keepPosition) {
      x = win_ref.current.width / 2;
      y = win_ref.current.height / 2;
    }

    // update position such that the cursor remains over the same pixel of the
    // image after the zoom is applied
    const pos_relative = {
      x: (pos.x + x) / zoom_ref.current,
      y: (pos.y + y) / zoom_ref.current,
    };

    set_zoom_factor(new_zoom);
    set_position(
      clamp_position(
        {
          x: pos_relative.x * new_zoom - x,
          y: pos_relative.y * new_zoom - y,
        },
        dim_ref.current,
        win_ref.current,
        zoom_ref.current
      )
    );
  }, []);

  const on_zoom_in = useCallback(e => {
    // round because 1.4 + 0.1 becomes 1.4999999...
    const new_zoom = Math.min(
      Math.round(10 * (zoom_ref.current + ZOOM_STEP)) / 10,
      MAX_ZOOM
    );
    if (zoom_ref.current !== new_zoom) {
      apply_zoom(e, new_zoom);
    }
  }, []);

  const on_zoom_out = useCallback(
    e => {
      const new_zoom = Math.max(
        Math.round(10 * (zoom_ref.current - ZOOM_STEP)) / 10,
        min_zoom
      );
      if (zoom_ref.current !== new_zoom) {
        apply_zoom(e, new_zoom);
      }
    },
    [min_zoom]
  );

  // cooldown needed to prevent zooming to fast when scrolling with trackpad
  const scroll_cooldown = useRef<any>(null);
  const on_zoom = useCallback(
    e => {
      e.preventDefault();
      if (e.deltaY >= 16 || (e.deltaY > 1 && scroll_cooldown.current == null)) {
        on_zoom_out(e);
      } else if (
        e.deltaY <= -16 ||
        (e.deltaY < -1 && scroll_cooldown.current == null)
      ) {
        on_zoom_in(e);
      } else {
        return;
      }
      clearTimeout(scroll_cooldown.current);
      scroll_cooldown.current = setTimeout(() => {
        scroll_cooldown.current = null;
      }, 60);
    },
    [on_zoom_out, on_zoom_in]
  );

  const on_resize = useCallback(e => {
    const new_size = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    set_window_size(new_size);
    const new_zoom = Math.max(
      zoom_ref.current,
      calc_min_zoom(new_size, dim_ref.current)
    );
    if (new_zoom !== zoom_ref.current) {
      set_zoom_factor(new_zoom);
    }
    set_position(
      clamp_position(
        pos_ref.current,
        dim_ref.current,
        win_ref.current,
        new_zoom
      )
    );
  }, []);

  const [on_resize_db] = useDebounce(on_resize, 80);

  const on_cancel_grab = useCallback(() => {
    set_mouse_down(false);
    set_mouse_start(null);
    set_pan_pos(null);
  }, []);

  const zoom_in_btn = useRef<HTMLButtonElement>();
  const zoom_out_btn = useRef<HTMLButtonElement>();
  useEffect(() => {
    if (!!zoom_out_btn.current && !!zoom_in_btn.current) {
      zoom_in_btn.current?.addEventListener("touchstart", silence_event, opts);
      zoom_out_btn.current?.addEventListener("touchstart", silence_event, opts);
      return () => {
        zoom_in_btn.current?.removeEventListener(
          "touchstart",
          silence_event,
          opts
        );
        zoom_out_btn.current?.removeEventListener(
          "touchstart",
          silence_event,
          opts
        );
      };
    }
  }, [!!zoom_out_btn.current, !!zoom_in_btn.current]);

  useEffect(() => {
    window.addEventListener("resize", on_resize_db);
    window.addEventListener("cancel-grab", on_cancel_grab);
    return () => {
      window.removeEventListener("resize", on_resize_db);
      window.removeEventListener("cancel-grab", on_cancel_grab);
    };
  }, []);
  useEffect(() => {
    if (mouse_down) {
      window.addEventListener("touchend", on_mouse_up, opts);
      window.addEventListener("mouseup", on_mouse_up, opts);
      document.body.setAttribute("data-mouse-down", "true");
      return () => {
        window.removeEventListener("mouseup", on_mouse_up, opts);
        window.removeEventListener("touchend", on_mouse_up, opts);
        document.body.removeAttribute("data-mouse-down");
      };
    }
  }, [mouse_down]);
  useEffect(() => {
    if (panning) {
      document.body.setAttribute("data-panning", "true");
      window.addEventListener("blur", on_mouse_up, { capture: true });
      return () => {
        window.removeEventListener("blur", on_mouse_up, { capture: true });
        document.body.removeAttribute("data-panning");
      };
    }
  }, [panning]);

  useEffect(() => {
    if (init && currentAreaPosition) {
      const zoom = currentAreaPosition.zoom ?? 1;
      set_zoom_factor(zoom);
      set_position(
        clamp_position(
          {
            x:
              (currentAreaPosition.position[0] * dimensions.width * zoom) /
                100 -
              win_ref.current.width / 2,
            y:
              ((currentAreaPosition.position[1] - 4) *
                dimensions.height *
                zoom) /
                100 -
              win_ref.current.height / 2,
          },
          dim_ref.current,
          win_ref.current,
          zoom_ref.current
        )
      );
    }
  }, [currentAreaPosition]);

  const spring_pan_x = useMemo(() => {
    if (!dimensions) {
      return SPRING_PAN_CONFIG;
    }
    const factor = (1 + dimensions.height / dimensions.width) / 2;
    return {
      stiffness: factor * SPRING_PAN_CONFIG.stiffness,
      damping: factor * SPRING_PAN_CONFIG.damping,
    };
  }, [dimensions]);

  const style = useMemo(() => {
    const pos = panning ? pan_pos : position;
    // relative pos = % of image dimensions
    const pos_relative = pos
      ? {
          x: (100 * pos.x) / (dimensions.width * zoom_factor),
          y: (100 * pos.y) / (dimensions.height * zoom_factor),
        }
      : null;

    return pos_relative
      ? {
          zoom: spring(zoom_factor, SPRING_ZOOM_CONFIG),
          x: spring(pos_relative.x, SPRING_PAN_CONFIG),
          y: spring(pos_relative.y, spring_pan_x),
        }
      : {
          zoom: spring(zoom_factor, SPRING_ZOOM_CONFIG),
          x: 50,
          y: 50,
        };
  }, [spring_pan_x, dimensions, pan_pos, position, zoom_factor, panning]);

  const background = (
    <img
      key="map-background"
      ref={img_ref}
      className="map-background"
      src={`${IMAGES_PREFIX}/uwcm-map.png`}
      alt=""
      draggable={false}
      onLoad={do_init}
    />
  );

  if (!init) {
    return <div className="map-container">{background}</div>;
  }

  return (
    <div
      className="map-container"
      onMouseDown={on_mouse_down}
      onTouchStart={wrap_touch_listener(on_mouse_down)}
      onMouseMove={mouse_down ? on_mouse_move : undefined}
      onTouchMove={mouse_down ? wrap_touch_listener(on_mouse_move) : undefined}
      onWheel={on_zoom}
      onPointerMove={
        mouse_down
          ? e => {
              e.currentTarget.setPointerCapture(e.pointerId);
            }
          : undefined
      }
    >
      <div className="map-zoom">
        <ButtonWithIcon
          ref={zoom_in_btn}
          className="btn-primary"
          icon={
            <img
              draggable={false}
              src={`${IMAGES_PREFIX}/MagnifyingGlassPlus.svg`}
              alt=""
            />
          }
          onMouseDownCapture={silence_event}
          onClickCapture={e => {
            e.preventDefault();
            // @ts-expect-error:
            e.keepPosition = true;
            on_zoom_in(e);
          }}
          disabled={zoom_factor >= MAX_ZOOM}
        />
        <ButtonWithIcon
          ref={zoom_out_btn}
          className="btn-primary"
          icon={
            <img
              draggable={false}
              src={`${IMAGES_PREFIX}/MagnifyingGlassMinus.svg`}
              alt=""
            />
          }
          onMouseDownCapture={silence_event}
          onClick={e => {
            e.preventDefault();
            // @ts-expect-error:
            e.keepPosition = true;
            on_zoom_out(e);
          }}
          disabled={zoom_factor <= min_zoom}
        />
      </div>
      <Motion
        style={style}
        children={[
          ({ zoom, x, y }) => (
            <div
              style={
                x >= 0 && y >= 0
                  ? {
                      transform: `scale(${zoom}) translate(-${x}%, -${y}%)`,
                    }
                  : { transform: `scale(${zoom})` }
              }
              className="map"
              draggable={false}
              data-panning={`${panning}`}
              data-mouse-down={`${mouse_down}`}
            >
              {background}
              <div className="map-content">
                <ZOOM_FACTOR.Provider value={(1 + 1 / zoom) / 2}>
                  {children}
                </ZOOM_FACTOR.Provider>
              </div>
            </div>
          ),
        ]}
      />
    </div>
  );
});
