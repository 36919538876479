import * as Preact from "preact";
import { route } from "preact-router";
import { useEffect } from "preact/hooks";
import { WithMapAreaData, RoutedApp } from "~/view/components";

export const App: Preact.FunctionComponent = () => {
  useEffect(() => {
    route("/");
  }, []);
  return (
    <WithMapAreaData>
      <RoutedApp />
    </WithMapAreaData>
  );
};
