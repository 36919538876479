import * as Preact from "preact";
import { useContext, useEffect, useMemo, useRef } from "preact/hooks";
import { AREA_ORDER, CURRENT_AREA } from "~/model";
import {
  ArrowIcon,
  ButtonWithIcon,
  CloseIcon,
  Label,
  ModalContent,
  useModal,
} from "~/view/components";
import { reset_page_zoom } from "~/view/utils";

const silence_event = e => {
  e.stopPropagation();
};

export const MapItemDetails: Preact.FunctionComponent<{ data: AreaData }> = ({
  data,
}) => {
  const { set_current_area } = useContext(CURRENT_AREA);
  const order = useContext(AREA_ORDER) ?? [];
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!container.current) {
      return;
    }
    container.current.addEventListener("touchstart", silence_event, {
      capture: true,
    });
    container.current.addEventListener("touchend", silence_event, {
      capture: true,
    });
    return () => {
      container.current.removeEventListener("touchstart", silence_event, {
        capture: true,
      });
      container.current.removeEventListener("touchend", silence_event, {
        capture: true,
      });
    };
  }, []);

  const area_index = useMemo(
    () => order?.indexOf(data.id) ?? -1,
    [data.id, order]
  );

  const [Modal, set_open] = useModal(undefined, reset_page_zoom);

  return (
    <div
      ref={container}
      className="map-item-details"
      onMouseDownCapture={silence_event}
      onMouseUpCapture={silence_event}
    >
      <div className="map-item-details-header">
        <Label>{data.content.label}</Label>
        <ButtonWithIcon
          className="btn-white"
          icon={<CloseIcon />}
          onClick={() => {
            set_current_area(null);
          }}
        />
      </div>
      <div className="map-item-details-body">{data.content.summary}</div>
      <div className="map-item-details-footer">
        <ButtonWithIcon
          className="btn-secondary"
          icon={<ArrowIcon direction="left" />}
          disabled={area_index <= 0}
          onClickCapture={() => {
            set_current_area(order[Math.max(0, area_index - 1)]);
          }}
        />
        <button className="btn-primary" onClickCapture={() => set_open(true)}>
          Take a Closer Look
        </button>
        <ButtonWithIcon
          disabled={area_index >= order?.length - 1}
          className="btn-secondary"
          icon={<ArrowIcon />}
          onClickCapture={() => {
            set_current_area(order[Math.min(order.length - 1, area_index + 1)]);
          }}
        />
      </div>
      <Modal className="area-modal">
        <ModalContent
          content={data.content}
          dismiss={() => {
            set_open(false);
            reset_page_zoom();
          }}
        />
      </Modal>
    </div>
  );
};
