import * as Preact from "preact";
import * as hooks from "preact/hooks";
import {
  AREA_ORDER,
  CURRENT_AREA,
  MAP_AREAS,
  SET_VIEWED_AREA,
  VIEWED_AREAS,
} from "~/model";
import {
  make_title,
  useDocumentTitle,
  useStateIfMounted,
  useStateRef,
} from "~/view/utils";
import content_data from "~/../site/static/data/content.json";
import map_data from "~/../site/static/data/map.json";
import order_data from "~/../site/static/data/order.json";
import illustration_data from "~/../site/static/data/illustrations.json";

// If/when the app is changed to get content from a CMS instead of static data,
// start here.
const get_data = async (): Promise<readonly [ObjectOf<AreaData>, string[]]> => {
  const data: ObjectOf<AreaData> = {};
  // @ts-expect-error:
  content_data.forEach((item: AreaContent) => {
    const { key, ...layout_data } = map_data.find(d => d.key === item.key);
    if (!layout_data) {
      return;
    }
    const illustration = illustration_data.find(d => d.key === item.key);
    data[item.key] = {
      id: item.key,
      content: {
        ...item,
        // @ts-expect-error:
        illustration,
      },
      // @ts-expect-error:
      layout: layout_data as AreaLayout,
    };
  });
  return [data, order_data] as const;
};

export const WithMapAreaData: Preact.FunctionComponent = ({ children }) => {
  const [data, set_data] = useStateIfMounted<ObjectOf<AreaData> | null>(null);
  const [order, set_order] = useStateIfMounted<string[] | null>(null);
  const [viewed, set_viewed, viewed_ref] = useStateRef<string[]>([]);
  const [current_area, set_current_area] = useStateIfMounted<string | null>(
    null
  );

  const add_viewed_area = hooks.useCallback((area: string) => {
    set_viewed(viewed_ref.current.concat([area]));
  }, []);

  hooks.useEffect(() => {
    get_data().then(([app_data, order_]) => {
      set_data(app_data);
      set_order(order_);
      set_current_area(order_[0]);
      add_viewed_area(order_[0]);
    });
  }, []);

  hooks.useEffect(() => {
    set_order(viewed.concat(order?.filter(k => !viewed.includes(k))));
  }, [viewed]);

  hooks.useEffect(() => {
    if (current_area && !viewed.includes(current_area)) {
      add_viewed_area(current_area);
    }
  }, [current_area]);

  const cur_area_value = hooks.useMemo(
    () => ({ current_area, set_current_area }),
    [current_area]
  );

  useDocumentTitle(() => {
    if (!data || !data[current_area] || current_area === "intro") {
      return make_title([]);
    }
    return make_title([data[current_area].content.label]);
  }, [current_area, !!data]);

  const ctxs = [
    [MAP_AREAS.Provider, data],
    [AREA_ORDER.Provider, order],
    [CURRENT_AREA.Provider, cur_area_value],
    [VIEWED_AREAS.Provider, viewed],
    [SET_VIEWED_AREA.Provider, add_viewed_area],
  ] as const;

  return (
    <Preact.Fragment>
      {ctxs.reduceRight(
        (children_, [Provider, value]) => (
          // @ts-expect-error:
          <Provider value={value}>{children_}</Provider>
        ),
        children
      )}
    </Preact.Fragment>
  );
};
