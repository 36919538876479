import * as Preact from "preact";
import { getCurrentUrl, route, Router } from "preact-router";
import { useContext, useEffect } from "preact/hooks";
import { CURRENT_AREA } from "~/model";
import { View } from "~/view/components";

export const RoutedApp: Preact.FunctionComponent = () => {
  const { current_area } = useContext(CURRENT_AREA);

  useEffect(() => {
    if (getCurrentUrl() !== `/${current_area || ""}`) {
      route(`/${current_area || ""}`, true);
    }
  }, [current_area]);

  return (
    <Router>
      <View default />
    </Router>
  );
};
