import * as Preact from "preact";
import { memo } from "preact/compat";
import { useContext, useEffect, useMemo } from "preact/hooks";
import { AREA_ORDER } from "~/model";
import { ArrowIcon, ButtonWithIcon, Label } from "~/view/components";
import { useStateIfMounted } from "~/view/utils";

const silence_event = e => {
  e.stopPropagation();
};

export const Intro: Preact.FunctionComponent<{
  data: AreaData;
  active: boolean;
  doTransition?: boolean;
  setCurrentArea: (area: string) => void;
}> = memo(({ data, active, doTransition, setCurrentArea }) => {
  const order = useContext(AREA_ORDER) ?? [];

  const area_index = useMemo(
    () => order?.indexOf(data.id) ?? -1,
    [data.id, order]
  );

  const [is_active, set_is_active] = useStateIfMounted(active);
  useEffect(() => {
    set_is_active(active);
  }, [active]);

  return (
    <div
      className="map-item map-intro"
      data-active={`${is_active}`}
      data-transition={`${doTransition}`}
    >
      <div
        className="map-item-details map-intro-details"
        onMouseDownCapture={silence_event}
        onTouchStartCapture={silence_event}
        onMouseUpCapture={silence_event}
        onTouchEndCapture={silence_event}
      >
        <div className="map-item-details-header map-intro-header">
          <Label>{data.content.headline}</Label>
        </div>
        <div className="map-intro-subhead">
          {data.content.subhead ||
            "Nisi accumsan nunc sagittis, tempor eget mauris, morbi diam. Sed porttitor urna adipiscing faucibus."}
        </div>
        <div className="map-intro-body">
          {data.content.body ||
            "Nisi accumsan nunc sagittis, tempor eget mauris, morbi diam. Sed porttitor urna adipiscing faucibus. Diam penatibus habitasse in aliquet urna sagittis, dolor velit morbi. Sed quis quam eu sagittis, feugiat duis urna nisi. Nulla enim gravida at fringilla vehicula nunc. A sit justo mauris viverra risus."}
        </div>
        <div className="map-intro-footer">
          <ButtonWithIcon
            className="btn-primary"
            icon={<ArrowIcon direction="right" />}
            side="right"
            onClickCapture={() => {
              setCurrentArea(order[Math.max(0, area_index + 1)]);
            }}
          >
            Start Exploring
          </ButtonWithIcon>
        </div>
      </div>
    </div>
  );
});
